(<template>
  <shared-pricing :show-draft-note="true"
                  class="feedback-invoice-draft">
    <travel-info v-if="isTravelCostsEnabled"
                 class="feedback-invoice-draft__travel-info" />
    <p class="feedback-invoice-draft__note">{{ draftInvoiceText }}</p>
    <feedback-actions />
    <sk-note v-if="cancellationNoteText"
             :text="cancellationNoteText"
             class="feedback-invoice-draft__cancel-policy" />
  </shared-pricing>
</template>)

<script>
  import {mapState} from 'vuex';
  import SharedPricing from '@/components/assignment_components/one_assignment/interactive_info/shared_components/SharedPricing';
  import TravelInfo from '@/components/assignment_components/one_assignment/interactive_info/shared_components/TravelInfo';
  import FeedbackActions from '@/components/assignment_components/one_assignment/interactive_info/pt_actions/FeedbackActions';
  import CancellationPolicyPrototype from '@/prototypes/CancellationPolicyPrototype';

  export default {
    components: {
      'shared-pricing': SharedPricing,
      'travel-info': TravelInfo,
      'feedback-actions': FeedbackActions
    },
    extends: CancellationPolicyPrototype,
    computed: {
      ...mapState('OneAssignmentStore', {
        priceRequirement: ({storeJobObj}) => {
          return storeJobObj ? storeJobObj.priceRequirement || {} : {};
        }
      }),
      isTravelCostsEnabled() { return this.priceRequirement.travelCost; },
      draftInvoiceText() {
        return this.$gettext('This total amount for this assignment will be updated after we lock this assignment. Locking will take place after we have gathered all necessary feedback.');
      },
    }
  };
</script>

<style scoped>
  .feedback-invoice-draft__note {
    margin-top: 10px;
    color: #ff712c;
    opacity: 0.8;
  }

  .feedback-invoice-draft__cancel-policy {
    margin-top: 10px;
  }
</style>
