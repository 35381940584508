(<template>
  <div class="feedback-actions">
    <div class="feedback-actions__button-wrapper">
      <a v-if="supportPortalLink"
         :href="supportPortalLink"
         class="sk-btn sk-btn--white feedback-actions__btn"
         target="_blank">{{ $gettext('Frequently asked questions') }}</a>
    </div>
  </div>
</template>)

<script>
  export default {
    data() {
      return {
        supportPortalLink: this.$getString('domainData', 'supportPortalLink')
      };
    }
  };
</script>

<style scoped>
  .feedback-actions {
    display: flex;
    margin-top: 30px;
  }

  .feedback-actions__button-wrapper {
    display: block;
    width: 100%;
    max-width: 265px;
  }

  @media (max-width: 767px) {
    .feedback-actions {
      flex-wrap: wrap;
    }

    .feedback-actions__button-wrapper {
      max-width: 100%;
    }
  }
</style>
